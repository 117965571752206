import { AnimatePresence, useCycle, motion } from 'framer-motion'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { fetchData } from '../../utils/FetchData'
import MainNav from './MainNav'
import StickyNavComponent from './StickyNavComponent'
import SubNav from './SubNav'

const Navbar = () => {
    const { locale } = useRouter()
    const { data, isSuccess } = useQuery(
        ["developmentMenu", locale],
        () =>
            fetchData(
                "home",
                locale,
                "populate[0]=Our_Portfolio.Project_Tabs.data&populate[1]=Our_Portfolio.Project_Tabs.projects&populate[2]=Our_Portfolio.Project_Tabs.projects.Home_Card&populate[3]=Our_Portfolio.Project_Tabs.projects.Home_Card.Img_Desktop&populate[4]=Our_Portfolio.Project_Tabs.business_projects&populate[5]=Our_Portfolio.Project_Tabs.business_projects.Home_Card&populate[6]=Our_Portfolio.Project_Tabs.business_projects.Home_Card.Img_Desktop&populate[7]=Our_Portfolio.Project_Tabs.commercial_projects&populate[8]=Our_Portfolio.Project_Tabs.commercial_projects.Home_Card&populate[9]=Our_Portfolio.Project_Tabs.commercial_projects.Home_Card.Img_Desktop&populate[10]=Our_Portfolio.Project_Tabs.business_projects.Home_Card.Logo&populate[11]=Our_Portfolio.Project_Tabs.commercial_projects.Home_Card.Logo&populate[12]=Our_Portfolio.Project_Tabs.projects.Home_Card.Logo"
            )
    )
    const [tabs, setTaps] = useState()
    useEffect(() => {
        isSuccess && setTaps(data?.data?.attributes?.Our_Portfolio?.Project_Tabs?.data)
    }, [isSuccess, data])
    return (
        <>
            <AnimatePresence>
                <motion.div className={`nav-bar-container`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{
                        opacity: 0,
                        transition: {
                            delay: 0,
                            duration: 0.5
                        },
                    }}>
                    <SubNav />
                    <MainNav tabs={tabs} />
                </motion.div>
            </AnimatePresence>
            <StickyNavComponent tabs={tabs} />
        </>
    )
}

export default Navbar