import Image from "next/legacy/image";
import React, { useContext, useEffect, useState } from "react";
import { FaLinkedinIn, FaFacebookF, FaTiktok } from "react-icons/fa";

import { BsInstagram, BsYoutube } from "react-icons/bs";
import { isMobileContext } from "../../contexts/isMobileContext";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { phaseColorContext } from "../../contexts/phaseColorContext";
import FooterForm from "./FooterForm";
import ArrowsSpliting from "../Animations/ArrowsSpliting";
import { useRouter } from "next/router";
import FadeIn from "../Animations/FadeIn";
import { useQuery } from 'react-query'
import { fetchData } from "@/utils/FetchData";
const Footer = () => {
	const { t } = useTranslation("common");
	const { isMobile } = useContext(isMobileContext);
	const { phaseColor } = useContext(phaseColorContext);
	const { asPath, locale } = useRouter()
	const [projects, setProjects] = useState([]) 
	const { data, isSuccess } = useQuery(
		["footer", locale],
		() =>
			fetchData(
				"home",
				locale,
				"populate[0]=Our_Portfolio.Project_Tabs.data&populate[1]=Our_Portfolio.Project_Tabs.projects&populate[2]=Our_Portfolio.Project_Tabs.projects.Home_Card&populate[3]=Our_Portfolio.Project_Tabs.projects.Home_Card.Img_Desktop&populate[4]=Our_Portfolio.Project_Tabs.business_projects&populate[5]=Our_Portfolio.Project_Tabs.business_projects.Home_Card&populate[6]=Our_Portfolio.Project_Tabs.business_projects.Home_Card.Img_Desktop&populate[7]=Our_Portfolio.Project_Tabs.commercial_projects&populate[8]=Our_Portfolio.Project_Tabs.commercial_projects.Home_Card&populate[9]=Our_Portfolio.Project_Tabs.commercial_projects.Home_Card.Img_Desktop&populate[10]=Our_Portfolio.Project_Tabs.business_projects.Home_Card.Logo&populate[11]=Our_Portfolio.Project_Tabs.commercial_projects.Home_Card.Logo&populate[12]=Our_Portfolio.Project_Tabs.projects.Home_Card.Logo"
			)
	)
	console.log(locale , "locale");
	useEffect(() => {
		const allProjects = []
		if (isSuccess) {
			data?.data?.attributes?.Our_Portfolio?.Project_Tabs?.data?.map((tab, index) => {
				index != 0 &&
					tab?.attributes?.projects?.data?.map((project, index) => {
						allProjects.push({
							projectName: project?.attributes.Project_Name,
							projectSlug: project?.attributes.Slug,
						})
					})
				tab?.attributes?.business_projects?.data?.map(project => {
					allProjects.push({
						projectName: project?.attributes.Project_Name,
						projectSlug: project?.attributes.Slug,
					})
				})
				tab?.attributes?.commercial_projects?.data?.map(project => {
					allProjects.push({
						projectName: project?.attributes.Project_Name,
						projectSlug: project?.attributes.Slug,
					})
				})

			})
			setProjects([...allProjects])
		}
	}, [data, isSuccess])

 

	return (
		<div className={`footer-section`} style={{ background: phaseColor }}>
			<FadeIn customClass="logo-container">
				<Link href={'/'} passHref>
					<div className="img _eleX">
						<Image
							src={"/icons/newLogo.png"}
							alt={"logo"}
							layout="fill"
							objectFit="contain"
							priority
						/>
					</div>
				</Link>
				<FooterForm />
				{isMobile && <p className="_eleX">© Hyde Park Developments {(new Date).getFullYear()} - {t("footer.terms")}</p>}
			</FadeIn>
			<div className="links-container">
				<div className="links">
					<div className="quick">
						<FadeIn>
							<h6 className="_eleX">{t("footer.quick_links")}</h6>
						</FadeIn>
						<ul>
							<FadeIn>
								<li className={` _eleX ${asPath == ('/') ? 'active' : ''}`}>
									<Link href={'/'} passHref>
										<ArrowsSpliting>{t("footer.home")}</ArrowsSpliting>
									</Link>
								</li>
								<li className={`_eleX ${asPath == ('/who-we-are') ? 'active' : ''}`}>
									<Link href={'/who-we-are'} passHref>
										<ArrowsSpliting>{t("footer.who_we")}</ArrowsSpliting>
									</Link>
								</li>
								<li className={`_eleX ${asPath == ('/services') ? 'active' : ''}`}>
									<Link href={'/services'} passHref>
										<ArrowsSpliting>{t("footer.services")}</ArrowsSpliting>
									</Link>
								</li>
								<li className={`_eleX ${asPath == ('/360-works') ? 'active' : ''}`} style={{ direction: "ltr" }}>
									<Link href={'/360-works'} passHref>
										<ArrowsSpliting works>360 WORKS</ArrowsSpliting>
									</Link>
								</li>
								<li className={`_eleX ${asPath == ('/media-center') ? 'active' : ''}`}>
									<Link href={'/media-center'} passHref>
										<ArrowsSpliting>{t("footer.media_center")}</ArrowsSpliting>
									</Link>
								</li>
								<li className="_eleX"
									onClick={() => {
										document.querySelector("#complainForm").classList.add("show"),
											document.body.classList.add("no-scroll")
									}}><ArrowsSpliting>{t("footer.complain_form")}</ArrowsSpliting></li>
								<li className={`_eleX`}>
									<Link target="_blank" href={locale == "ar" ? "https://careers.hpd.com.eg/ar/" : "https://careers.hpd.com.eg"} passHref>
										<ArrowsSpliting>{t("footer.career")}</ArrowsSpliting>
									</Link>
								</li>
								<li className={`_eleX`}>
									<Link target="_blank" href={locale == "ar" ? "https://www.makaicabanas.com" : "https://www.makaicabanas.com"} passHref>
										<ArrowsSpliting>{t("footer.makai")}</ArrowsSpliting>
									</Link>
								</li>
							</FadeIn>
						</ul>
					</div>
					<div className="quick">
						<FadeIn>
							<h6 className="_eleX">{t("footer.developments")}</h6>
						</FadeIn>
						<FadeIn>
							<ul className="_eleX">
								<FadeIn>
									{projects?.map((project, index) => (
										<li className={`_eleX ${asPath == `/projects/${project?.projectSlug}` ? 'active' : ''}`} key={index}>
											<Link href={`/projects/${project?.projectSlug}`} passHref>
												<ArrowsSpliting>{project?.projectName}</ArrowsSpliting>
											</Link>
										</li>
									))}
								</FadeIn>
							</ul>
						</FadeIn>
					</div>
					{!isMobile && (
						<>
							<div className="border"></div>
							<FadeIn customClass="emails">
								<div className="email-container _eleX">
									<h6 className="_eleX">{t("footer.email")}</h6>
									<a
										className="text _eleX"
										href="mailto:info@hpd.com.eg"
										target="_blank"
										rel="noreferrer"
										aria-label="email"
									>
										info@hpd.com.eg
									</a>
								</div>
								<div className="email-container">
									<h6 className="_eleX">{t("footer.hotline")}</h6>
									<a
										className="text _eleX"
										href="tel://16696"
										target="_blank"
										rel="noreferrer"
										aria-label="phone number"
									>
										16696
									</a>
								</div>
							</FadeIn>
							<div className="icons-container">
								<FadeIn>

									<h6 className="_eleX">{t("footer.social_media")}</h6>
								</FadeIn>
								<FadeIn customClass="icons">
									<a
										aria-label="facebook"
										className="_eleX"
										href="https://www.facebook.com/HydeParkDevelopments"
										target="_blank"
										rel="noreferrer">
										<span>facebook</span>
										<FaFacebookF
											color="white"
											size={isMobile ? 30 : 20}
											cursor="pointer"
										/>
									</a>
									<a
										href="https://www.instagram.com/hydeparkdevelopments/"
										target="_blank"
										rel="noreferrer"
										aria-label="instagram"
										className="_eleX"
									>
										<span>instagram</span>
										<BsInstagram
											color="white"
											size={isMobile ? 30 : 17}
											cursor="pointer"
										/>
									</a>
									<a
										href="https://www.tiktok.com/@hydeparkdevelopments"
										target="_blank"
										rel="noreferrer"
										aria-label="tiktok"
										className="_eleX"
									>
										<span>tiktok</span>
										<FaTiktok
											color="white"
											size={isMobile ? 30 : 17}
											cursor="pointer"
										/>
									</a>
									<a
										href="https://www.linkedin.com/company/hyde-park-properties-for-development/"
										target="_blank"
										rel="noreferrer"
										aria-label="linkedin"
										className="_eleX"
									>
										<span>linkedin</span>
										<FaLinkedinIn
											color="white"
											size={isMobile ? 30 : 20}
											cursor="pointer"
										/>
									</a>
									<a
										href="https://www.youtube.com/HydeParkDevelopment"
										target="_blank"
										rel="noreferrer"
										aria-label="youtube"
										className="_eleX"
									>
										<span>youtube</span>
										<BsYoutube
											color="white"
											size={isMobile ? 30 : 20}
											cursor="pointer"
										/>
									</a>
								</FadeIn>
							</div>
						</>
					)}
				</div>
				{isMobile && (
					<div className="social">
						<div className="icons-container">
							<h6>{t("footer.social_media")}</h6>
							<div className="icons">
								<a
									href="https://www.facebook.com/HydeParkDevelopments"
									target="_blank"
									rel="noreferrer"
									aria-label="facebook"
								>
									<span>facebook</span>
									<FaFacebookF
										color="white"
										size={isMobile ? 30 : 20}
										cursor="pointer"
									/>
								</a>
								<a
									href="https://www.instagram.com/hydeparkdevelopments/"
									target="_blank"
									rel="noreferrer"
									aria-label="instagram"
								>
									<span>instagram</span>
									<BsInstagram
										color="white"
										size={isMobile ? 30 : 17}
										cursor="pointer"
									/>
								</a>
								<a
									href="https://www.linkedin.com/company/hyde-park-properties-for-development/"
									target="_blank"
									rel="noreferrer"
									aria-label="linkedin"
								>
									<span>linkedin</span>
									<FaLinkedinIn
										color="white"
										size={isMobile ? 30 : 20}
										cursor="pointer"
									/>
								</a>
								<a
									href="https://www.youtube.com/HydeParkDevelopment"
									target="_blank"
									rel="noreferrer"
									aria-label="youtube"
								>
									<span>youtube</span>
									<BsYoutube
										color="white"
										size={isMobile ? 30 : 20}
										cursor="pointer"
									/>
								</a>
							</div>
						</div>
						<div className="emails">
							<div className="email-container">
								<h6>Email</h6>
								<div className="text">customermanagement@Hydepark.com</div>
							</div>
							<div className="email-container">
								<h6>phone</h6>
								<div className="text">800-Hydepark / 800-25327</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className="copy-rights">
				<FadeIn>
				{!isMobile && <p className="_eleX">© Hyde Park Developments {(new Date).getFullYear()} - {t("footer.terms")}</p>}
			</FadeIn>
			<p className="harshita">
				{t("footer.designed_developed")}{" "}
				<a
					href="https://beyond-creation.net/"
					target="_blank"
					rel="noreferrer"
					aria-label="email"
					className="gotham"
				>
					Beyond Creation
				</a>
			</p>
			</div>
		</div>
	);
};

export default Footer;
