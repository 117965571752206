import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL } from '../../utils/FetchData'
import { Tabs, TabPanel, TabList, Tab } from "react-tabs";
import { Navigation, Pagination, EffectFade, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { useTranslation } from 'next-i18next'
import { nextRouteContext } from '../../contexts/nextRouteContext'
import { AnimatePresence, motion } from "framer-motion"
import { isMobileContext } from '../../contexts/isMobileContext'
import { isMenuOpendContext } from '../../contexts/isMenuOpendContext';
import Magnitizer from '../Animations/Magnitizer';
import FadeIn from '../Animations/FadeIn';
import ArrowsSpliting from '../Animations/ArrowsSpliting';
import Button from './Button';
const DropDownMenu = ({ toggleHover, tabs, stickyNav }) => {
    const router = useRouter()
    const { setNextRoute } = useContext(nextRouteContext);
    const { setIsDevelopMenuOpened } = useContext(isMenuOpendContext)
    const [imgHover, setImgHover] = useState(-1)
    const [selectedTab, setSelectedTab] = useState()
    const [slideNumber, setSlideNumber] = useState("0" + 1);
    const { t } = useTranslation('common')
    const toggleHoverMenu = (state, devMenuState) => {
        toggleHover(state)
        setIsDevelopMenuOpened(devMenuState)
    }
    useEffect(() => {
        tabs && setSelectedTab(tabs[0])
    }, [tabs])
    return (
        <motion.div className={`development-menu`}
            onHoverStart={() => toggleHoverMenu(true, true)}
            onHoverEnd={() => toggleHoverMenu(false,false)}
        >
            <Tabs>
                <div className="tabs-container">
                    <div className="tabs-header">
                        <TabList>
                            {tabs?.map((tab, index) => (
                                <Tab key={index} >
                                    {tab.attributes.projects?.data.length + tab?.attributes?.business_projects?.data.length +
                                        tab.attributes.commercial_projects?.data?.length > 0
                                        &&
                                        <motion.div
                                            initial={{ opacity: "0", }}
                                            whileInView={{ opacity: 1 }}
                                            transition={{ delay: parseFloat((index / 5) + 0.05), duration: .5 }}
                                        // key={index}
                                        >
                                            <span className='bold' >
                                                <ArrowsSpliting xValue={'15%'}>
                                                    {tab?.attributes?.name}
                                                </ArrowsSpliting>
                                            </span>
                                        </motion.div>
                                    }
                                </Tab>
                            ))}
                        </TabList>
                    </div>
                    <div className="tabs-panels">
                        {tabs?.map(
                            (tab, index) =>
                                <TabPanel key={index + "tabs"}>
                                    <div className={`portfolio-swiper`}>
                                        <Swiper
                                            key={
                                                "portfolio" +
                                                tab.attributes.projects?.data
                                                    .length
                                            }
                                            style={{ direction: router.locale == "ar" ? "rtl" : "ltr" }}
                                            modules={[
                                                Navigation,
                                                Pagination,
                                                EffectFade,
                                                Mousewheel,
                                            ]}
                                            
                                            navigation={{
                                                nextEl: ".swiper-button-next-dropdown",
                                                prevEl: ".swiper-button-prev-dropdown",
                                            }}
                                            className="swiper-dropdown-section"
                                            slidesPerView={3}
                                            spaceBetween={30}
                                            onSlideChange={(swiper) => {
                                                setSlideNumber("0" + (swiper.realIndex + 1));
                                            }}
                                            pagination={{
                                                type: "progressbar",
                                                el: ".swiper-dropdown-navigation",
                                                clickable: true,
                                            }}
                                        >
                                            {tab?.attributes?.projects?.data.map(
                                                (data, index) => (
                                                    <SwiperSlide key={index + "panel"}>
                                                        <motion.div className="img-container"
                                                            initial={{ opacity: 0 }}
                                                            // key={index}
                                                            exit={{ opacity: 0 }}
                                                            whileInView={{ opacity: 1 }}
                                                            transition={{ duration: 0.25, delay: parseFloat(index / 5 + .1) }}
                                                            onHoverStart={() => setImgHover(index)}
                                                            onHoverEnd={() => setImgHover(-1)}>
                                                            <div className="overlay" ></div>
                                                            <FadeIn durationY={.25} delayY={0}>
                                                                <div className='project-name _eleY'
                                                                    onClick={() => { setNextRoute(data.attributes.Project_Name); router.push(`/projects/${data.attributes.Slug}`) }}
                                                                >{data.attributes.Project_Name}</div>
                                                            </FadeIn>
                                                            <div className="main-logo">
                                                                <Image
                                                                    src={
                                                                        data?.attributes?.Home_Card?.Logo?.data?.attributes?.url ? BASE_URL +
                                                                            data?.attributes?.Home_Card?.Logo?.data?.attributes?.url :
                                                                            "/icons/project-logo.svg"
                                                                    }
                                                                    alt={
                                                                        data?.attributes?.Home_Card?.Logo?.data?.attributes?.name ?
                                                                            data?.attributes?.Home_Card?.Logo?.data?.attributes?.name : "logo"
                                                                    }
                                                                    width={100}
                                                                    height={100}
                                                                />
                                                            </div>
                                                            <span className='read-more'
                                                                onClick={() => { setNextRoute(data.attributes.Project_Name),router.push(`/projects/${data.attributes.Slug}`),toggleHoverMenu(false,false)}}
                                                            >
                                                                <Button
                                                                    // text={'Explore project'}
                                                                    text={t("common.explore_project")}
                                                                    url={`/projects/${data.attributes.Slug}`}
                                                                />
                                                            </span>
                                                            <div className='hoverd'>
                                                                <span className='hoverd-items left'></span>
                                                                <span className='hoverd-items right'></span>
                                                                <span className='hoverd-items top'></span>
                                                                <span className='hoverd-items bottom'></span>
                                                            </div>
                                                            <Image
                                                                src={
                                                                    BASE_URL +
                                                                    data.attributes.Home_Card.Img_Desktop.data
                                                                        .attributes.url
                                                                }
                                                                alt={
                                                                    data.attributes.Home_Card.Img_Desktop.data
                                                                        .attributes.name
                                                                }
                                                                layout="fill"
                                                                objectFit="cover"
                                                            />
                                                        </motion.div>
                                                    </SwiperSlide>
                                                )
                                            )}
                                            {tab?.attributes?.business_projects?.data.map(
                                                (data, businessIndex) => (
                                                    <SwiperSlide key={businessIndex + "panel"}>
                                                        <motion.div className="img-container"
                                                            initial={{ opacity: 0 }}
                                                            exit={{ opacity: 0 }}
                                                            whileInView={{ opacity: 1 }}
                                                            transition={{ duration: 0.25, delay: parseFloat((businessIndex + tab?.attributes?.projects?.data?.length - 1) / 10 + .1) }}
                                                            onHoverStart={() => setImgHover(
                                                                businessIndex +
                                                                tab?.attributes?.projects?.data?.length
                                                            )}
                                                            onHoverEnd={() => setImgHover(-1)}
                                                            onClick={() => { setNextRoute(data.attributes.Project_Name); router.push(`/projects/${data.attributes.Slug}`) }}
                                                        >
                                                            <div className="overlay" ></div>
                                                            <FadeIn durationY={.25} delayY={0}>
                                                                <div className='project-name _eleY'
                                                                    onClick={() => { setNextRoute(data.attributes.Project_Name); router.push(`/projects/${data.attributes.Slug}`) }}
                                                                >{data.attributes.Project_Name}</div>
                                                            </FadeIn>
                                                            <div className="main-logo">
                                                                <Image
                                                                    src={
                                                                        data?.attributes?.Home_Card?.Logo?.data?.attributes?.url ? BASE_URL +
                                                                            data?.attributes?.Home_Card?.Logo?.data?.attributes?.url :
                                                                            "/icons/project-logo.svg"
                                                                    }
                                                                    alt={
                                                                        data?.attributes?.Home_Card?.Logo?.data?.attributes?.name ?
                                                                            data?.attributes?.Home_Card?.Logo?.data?.attributes?.name : "logo"
                                                                    }
                                                                    width={100}
                                                                    height={100}
                                                                />
                                                            </div>
                                                            <span className='read-more'
                                                                onClick={() => { setNextRoute(data.attributes.Project_Name),router.push(`/projects/${data.attributes.Slug}`),toggleHoverMenu(false,false) }}
                                                            >
                                                                <Button
                                                                    text={t("common.explore_project")}
                                                                    // text={'Explore project'}
                                                                    url={`/projects/${data.attributes.Slug}`}
                                                                />
                                                            </span>
                                                            <div className='hoverd'>
                                                                <span className='hoverd-items left'></span>
                                                                <span className='hoverd-items right'></span>
                                                                <span className='hoverd-items top'></span>
                                                                <span className='hoverd-items bottom'></span>
                                                            </div>
                                                            <Image
                                                                src={
                                                                    BASE_URL +
                                                                    data?.attributes?.Home_Card?.Img_Desktop?.data?.attributes?.url
                                                                }
                                                                alt={
                                                                    data?.attributes?.Home_Card?.Img_Desktop?.data?.attributes?.name
                                                                }
                                                                layout="fill"
                                                                objectFit="cover"
                                                            />
                                                        </motion.div>
                                                    </SwiperSlide>
                                                )
                                            )}
                                            {tab?.attributes?.commercial_projects?.data.map(
                                                (data, commercialIndex) => (
                                                    <SwiperSlide key={commercialIndex + "panel"}>
                                                        <motion.div className="img-container"
                                                            initial={{ opacity: 0 }}
                                                            exit={{ opacity: 0 }}
                                                            whileInView={{ opacity: 1 }}
                                                            transition={{ duration: 0.25, delay: parseFloat((commercialIndex + tab?.attributes?.business_projects?.data?.length - 1 + tab?.attributes?.projects?.data?.length - 1) / 5 + .1) }}
                                                            onHoverStart={() => setImgHover(commercialIndex + 100)}
                                                            onHoverEnd={() => setImgHover(-1)}
                                                            onClick={() => { setNextRoute(data.attributes.Project_Name); router.push(`/projects/${data.attributes.Slug}`) }}
                                                        >
                                                            <div className="overlay" ></div>
                                                            <FadeIn durationY={.25} delayY={0}>
                                                                <div className='project-name _eleY'
                                                                    onClick={() => { setNextRoute(data.attributes.Project_Name); router.push(`/projects/${data.attributes.Slug}`) }}
                                                                >{data.attributes.Project_Name}</div>
                                                            </FadeIn>
                                                            <div className="main-logo">
                                                                <Image
                                                                    src={
                                                                        data?.attributes?.Home_Card?.Logo?.data?.attributes?.url ? BASE_URL +
                                                                            data?.attributes?.Home_Card?.Logo?.data?.attributes?.url :
                                                                            "/icons/project-logo.svg"
                                                                    }
                                                                    alt={
                                                                        data?.attributes?.Home_Card?.Logo?.data?.attributes?.name ?
                                                                            data?.attributes?.Home_Card?.Logo?.data?.attributes?.name : "logo"
                                                                    }
                                                                    width={100}
                                                                    height={100}
                                                                />
                                                            </div>
                                                            <span className='read-more'
                                                                onClick={() => { setNextRoute(data.attributes.Project_Name),router.push(`/projects/${data.attributes.Slug}`),toggleHoverMenu(false,false) }}
                                                            >
                                                                <Button
                                                                    text={t("common.explore_project")}
                                                                    // text={'Explore project'}
                                                                    url={`/projects/${data.attributes.Slug}`}
                                                                />
                                                            </span>
                                                            <div className='hoverd'>
                                                                <span className='hoverd-items left'></span>
                                                                <span className='hoverd-items right'></span>
                                                                <span className='hoverd-items top'></span>
                                                                <span className='hoverd-items bottom'></span>
                                                            </div>
                                                            <Image
                                                                src={
                                                                    BASE_URL +
                                                                    data?.attributes?.Home_Card?.Img_Desktop?.data?.attributes?.url
                                                                }
                                                                alt={
                                                                    data?.attributes?.Home_Card?.Img_Desktop?.data?.attributes?.name
                                                                }
                                                                layout="fill"
                                                                objectFit="cover"
                                                            />
                                                        </motion.div>
                                                    </SwiperSlide>
                                                )
                                            )}
                                        </Swiper>
                                        {tab.attributes.projects?.data?.length +
                                            tab.attributes.commercial_projects?.data?.length
                                            + tab?.attributes?.business_projects?.data.length > 3 && (
                                                <motion.div className="arrows-container"
                                                    initial={{ opacity: 0 }}
                                                    exit={{ opacity: 0 }}
                                                    whileInView={{ opacity: 1 }}
                                                    transition={{ duration: 0.25, delay: .25 }}
                                                >
                                                    <Magnitizer dataDest={1.5}>
                                                        <div className="swiper-button-prev swiper-button-prev-dropdown  dropdown-arrow black-arrow-prev"></div>
                                                    </Magnitizer>
                                                    <Magnitizer dataDest={1.5}>
                                                        <div className="swiper-button-next swiper-button-next-dropdown dropdown-arrow black-arrow-next"></div>
                                                    </Magnitizer>
                                                </motion.div>
                                            )}
                                    </div>
                                </TabPanel>
                        )}
                    </div>
                </div>
            </Tabs>
        </motion.div>
    )
}

export default DropDownMenu