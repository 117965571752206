import { createContext, useEffect, useState } from "react"

//create a context, with createContext api
export const isMenuOpendContext = createContext()

function IsMenuOpendProvider(props) {
    const [isMenuOpenedContext, setIsMenuOpenedContext] = useState(false)
    const [searchMenuOpened, setSearchMenuOpened] = useState(false)
    const [isDevelopMenuOpened, setIsDevelopMenuOpened] = useState(false)
    const [isBookingMenuOpened, setIsBookingMenuOpened] = useState(false)
    return (
        <>
            <isMenuOpendContext.Provider
                value={{
                    isMenuOpenedContext, setIsMenuOpenedContext,
                    searchMenuOpened, setSearchMenuOpened,
                    isDevelopMenuOpened, setIsDevelopMenuOpened,
                    isBookingMenuOpened, setIsBookingMenuOpened
                }}
            >
                {props.children}
            </isMenuOpendContext.Provider>
        </>
    )
}

export default IsMenuOpendProvider
