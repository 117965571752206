import { useField } from "formik"
import Select from "react-select"
import { customStyles3 } from "../../utils/SelectOptions"

const FormReactSelect = ({ label, ...props }) => {
	const [field, meta, { setValue, setTouched, setError }] = useField(props)

	return (
		<div>
			<label htmlFor={props.id || props.name} className="form-label">
				{label}
			</label>
			<Select
				defaultValue={options.find((option) => option.value === field.value)}
				options={options}
				getOptionLabel={(option) => option.label}
				getOptionValue={(option) => option.value}
				onChange={props.onChange}
				onBlur={setTouched}
				styles={customStyles3}
				placeholder={props.placeholder}
				isSearchable={false}
				className={`select-input ${
					meta.error && meta.touched ? "error-border" : ""
				}`}
				maxMenuHeight={"16.5rem"}
				name={props.name}
				isDisabled={disabled ? true : false}
				instanceId={"form"}
			/>
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</div>
	)
}

const FormTextInput = ({ label, ...props }) => {
	const [field, meta] = useField(props)
	return (
		<>
			<label htmlFor={props.id || props.name}>{label}</label>
			<input
				className={`text-input ${
					meta.error && meta.touched ? "error-border" : ""
				}`}
				{...field}
				{...props}
			/>

			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</>
	)
}

const FormNumberInput = ({ label, ...props }) => {
	const [field, meta] = useField(props)
	return (
		<>
			<label htmlFor={props.id || props.name}>{label}</label>
			<input
				className={`text-input ${
					meta.error && meta.touched ? "error-border" : ""
				}`}
				{...field}
				{...props}
			/>
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</>
	)
}

const FormTextFieldInput = ({ label, row, ...props }) => {
	const [field, meta] = useField(props)
	return (
		<>
			<label htmlFor={props.id || props.name}>{label}</label>
			<textarea rows={row} className="text-input" {...field} {...props} />
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</>
	)
}

const FormSelect = ({ label, ...props }) => {
	const [field, meta] = useField(props)
	return (
		<div>
			<label htmlFor={props.id || props.name}>{label}</label>
			<select {...field} {...props}  />
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</div>
	)
}
const CustomStyles = {
	menu: (provided, state) => ({
		...provided,
		backgroundColor: '#ffff',
		color: 'black',
		padding: '0',
		zIndex: '4',
		textTransform: 'capitalize',
	}),
	menuList: (provided, state) => ({
		...provided,
		padding: "0"
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: 'black',
		margin:0
		
	}),
	control: (provided, state) => ({
		...provided,
		backgroundColor: 'unset',
		boxShadow: 'none',
		borderRadius: '0rem',
		minHeight: 'unset',
		transition: '1s all',
		border:"none",
		zIndex: '1',
		color:'black',
		cursor: 'pointer',
		textTransform: 'capitalize',
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: 'black',
	}), 
	valueContainer: (provided, state) => ({
		...provided,
		padding:0,
		paddingInlineStart: "1rem"
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		display: 'none',
	}),
	indicatorsContainer: (provided, state) => ({
		...provided,
		paddingInlineEnd: "0.25rem"
	}),
	option: (provided, state) => ({
		...provided,
		cursor: "pointer",
		paddingInlineStart: "1rem",
		backgroundColor: state.isSelected ? "#31353d" : state.isFocused ? `#31353d50` : 'white',
		color: state.isSelected ? `white` : '#000',
		'&:hover': {
			backgroundColor: state.isSelected ? "#31353d" : `#31353d50`
		},
	}),
};

export {
	FormTextInput,
	FormTextFieldInput,
	FormSelect,
	FormReactSelect,
	FormNumberInput,
	CustomStyles
}
