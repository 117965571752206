
import { motion } from 'framer-motion'
import Link from 'next/link'
import React, { useContext, useState } from 'react'
import { useTranslation } from "next-i18next"
import Router, { useRouter } from 'next/router'
import { nextRouteContext } from '../../contexts/nextRouteContext'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from "@chakra-ui/react"
import Image from 'next/image'
import FadeIn from '../Animations/FadeIn'
import { isMenuOpendContext } from '@/contexts/isMenuOpendContext'
const MobileMenu = ({ tabs }) => {
    const { asPath, locale } = useRouter()
    const { setIsMenuOpenedContext } = useContext(isMenuOpendContext)
    const { t } = useTranslation('common')
    const { setNextRoute } = useContext(nextRouteContext);
    return (
        <motion.div className='mobile-menu'
            initial={{ x: locale == "en" ? 500 : -500 }}
            animate={{
                x: 0,
                transition: {
                    type: "spring",
                    stiffness: 400,
                    damping: 40,
                },
            }}
            exit={{
                x: locale == "en" ? 500 : -500,
                width: 0,
                transition: {
                    delay: 0,
                    type: "spring",
                    stiffness: 400,
                    damping: 40,
                },
            }}
        >
            <FadeIn customClass={`links-container bold`}>
                <div onClick={() => { setIsMenuOpenedContext(false); setNextRoute(t("navbar.home")); Router.push('/') }} className={`_eleX link ${asPath == '/' ? 'active' : ''}`}>{`${t("navbar.home")}`}</div>
                <div onClick={() => { setIsMenuOpenedContext(false); setNextRoute(t("navbar.who_we")); Router.push('/who-we-are') }} className={`link _eleX ${asPath.includes('/who-we-are') ? 'active' : ''}`}>{`${t("navbar.who_we")}`}</div>
                <div className={`link _eleX development ${asPath.includes('/projects') ? 'active-dev' : ''}`}>
                    <Accordion allowMultiple>
                        <AccordionItem>
                            <AccordionButton>
                                <div className={`button-name ${asPath.includes('/projects') ? 'active' : ''}`}>
                                    {`${t("navbar.developments")}`}
                                </div>
                                <AccordionIcon className={`${asPath.includes('/projects') ? 'active' : ''}`} />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Accordion allowMultiple >
                                    {tabs?.map((tab, index) => (
                                        <AccordionItem key={index}>
                                            <AccordionButton>
                                                <div className="button-name">
                                                    {tab?.attributes?.name}
                                                </div>
                                                <AccordionIcon />
                                            </AccordionButton>
                                            <AccordionPanel>
                                                <ul>
                                                    {tab.attributes.projects?.data.sort(
                                                        (a, b) =>
                                                            a.attributes.Order - b.attributes.Order
                                                    ).map((project, index) => (
                                                        <li
                                                            onClick={() => { setIsMenuOpenedContext(false); setNextRoute(project.attributes.Project_Name); Router.push(`/projects/${project.attributes.Slug}`) }}
                                                            className={`${asPath == `/projects/${project.attributes.Slug}` ? 'active' : ''}`} key={index}>{project.attributes.Project_Name}</li>
                                                    ))}
                                                    {tab.attributes.business_projects?.data.sort(
                                                        (a, b) =>
                                                            a.attributes.Order - b.attributes.Order
                                                    ).map((project, businessIndex) => (
                                                        <li
                                                            onClick={() => { setIsMenuOpenedContext(false); setNextRoute(project.attributes.Project_Name); Router.push(`/projects/${project.attributes.Slug}`) }}
                                                            className={`${asPath == `/projects/${project.attributes.Slug}` ? 'active' : ''}`} key={businessIndex}>{project.attributes.Project_Name}</li>
                                                    ))}
                                                    {tab.attributes.commercial_projects?.data.sort(
                                                        (a, b) =>
                                                            a.attributes.Order - b.attributes.Order
                                                    ).map((project, commercialIndex) => (
                                                        <li
                                                            onClick={() => { setIsMenuOpenedContext(false); setNextRoute(project.attributes.Project_Name); Router.push(`/projects/${project.attributes.Slug}`) }}
                                                            className={`${asPath == `/projects/${project.attributes.Slug}` ? 'active' : ''}`} key={commercialIndex}>{project.attributes.Project_Name}</li>
                                                    ))}
                                                </ul>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
                <div onClick={() => { setIsMenuOpenedContext(false); setNextRoute(t("navbar.services")); Router.push('/services') }} className={`link _eleX ${asPath.includes('/services') ? 'active' : ''}`}>{`${t("navbar.services")}`}</div>
                <div style={{ direction: "ltr" }} onClick={() => { setIsMenuOpenedContext(false); setNextRoute(t("navbar.360_works")); Router.push('/360-works') }} className={`link _eleX ${asPath.includes('/360-works') ? 'active' : ''}`}>
                    {`360 WORKS`}
                </div>
                <div onClick={() => { setIsMenuOpenedContext(false); setNextRoute(t("navbar.media_center")); Router.push('/media-center') }} className={`link _eleX ${asPath.includes('/media-center') ? 'active' : ''}`}>{`${t("navbar.media_center")}`}</div>
                <div onClick={() => { setIsMenuOpenedContext(false); setNextRoute(t("navbar.contactUs")); Router.push('/contact-us') }} className={`link _eleX ${asPath.includes('/contact-us') ? 'active' : ''}`}>{`${t("navbar.contactUs")}`}</div>
                <div onClick={() => { setIsMenuOpenedContext(false); setNextRoute(t("navbar.career"));  window.open(locale === "ar" ? "https://careers.hpd.com.eg/ar/" : "https://careers.hpd.com.eg", '_blank') }} className={`link _eleX`}>{`${t("navbar.career")}`}</div>
                <div onClick={() => { setIsMenuOpenedContext(false); setNextRoute(t("navbar.makai"));  window.open(locale === "ar" ? "https://www.makaicabanas.com" : "https://www.makaicabanas.com", '_blank') }} className={`link _eleX`}>{`${t("navbar.makai")}`}</div>
                <div onClick={() => { setIsMenuOpenedContext(false);document.querySelector("#complainForm").classList.add("show") }} className={`link _eleX`}>{`${t("footer.complain_form")}`}</div>
            </FadeIn>
            <div className='bg'>
                <div className='img-container'>
                    <Image
                        alt='bg'
                        src={"/icons/logo-background.svg"}
                        layout="fill"
                        objectFit='cover'
                    />
                </div>
            </div>
        </motion.div>
    )
}

export default MobileMenu
