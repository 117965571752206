
export const customStyles = {
	menu: (provided, state) => ({
		...provided,
		zIndex: 999,
		width: "15rem",
		// borderRadius: borderRadius,
	}),
	valueContainer: (provided, state) => ({
		...provided,
		padding: 0,
		paddingInlineStart: "1rem",
	}),
	indicatorSeparator: () => ({
		display: "none",
	}),
	menuList: (provided) => ({
		...provided,
		padding: "1rem 0",
		width: "15rem",
		boxShadow: "0px 7px 37px 0px #AEC0CFB5",
		border: "none",
		backgroundColor: "#fff",
		// // borderRadius: borderRadius,
	}),
	container: (provided, state) => ({
		...provided,
		width: "12rem",
	}),
	option: (provided, state) => ({
		...provided,
		width: "100%",
		padding: ".5rem 2rem",
		textTransform: "capitalize",
		color: state.isSelected ? "#fff" : "#000",
		backgroundColor: "#fff",
		backgroundColor: state.isSelected ? 'none' : "#fff",
		cursor: "pointer",
		width: "15rem",
		"&:hover": {
			backgroundColor: 'none',
			color: "#fff",
		},
	}),
	control: (provided, state) => ({
		...provided,
		backgroundColor: "#fff",
		display: "flex",
		color: "#000",
		border: "none",
		padding: ".6rem",
		// // borderRadius: borderRadius,
		outline: state.isFocused ? "none" : "none",
		boxShadow: "none",
		cursor: "pointer",
		minHeight: "25px",
		boxShadow: "0px 1px 3px 0px #AEC0CFB5",
	}),
	// control: (base) => ({
	// 	...base,
	// 	height: 25,
	// 	minHeight: 25,
	// }),
	clearIndicator: (styles) => ({
		...styles,
		paddingTop: 7,
		paddingBottom: 7,
	}),
	indicatorContainer: (provided, state) => ({
		...provided,
		padding: "0 !important",
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		primary: "white",
		border: "none",
		paddingTop: 4,
		paddingBottom: 4,
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: "#000",
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: "#000",
	}),
	input: (provided, state) => ({
		...provided,
		color: "#000",
	}),
}

export const customStylesMobile = {
	menu: (provided, state) => ({
		...provided,
		zIndex: 999,
		width: "100%",
		// // borderRadius: borderRadius,
	}),
	valueContainer: (provided, state) => ({
		...provided,
		padding: 0,
		paddingInlineStart: "1rem",
	}),
	indicatorSeparator: () => ({
		display: "none",
	}),
	menuList: (provided) => ({
		...provided,
		padding: "1rem 0",
		width: "100%",
		boxShadow: "0px 7px 37px 0px #AEC0CFB5",
		border: "none",
		backgroundColor: "#fff",
		// // borderRadius: borderRadius,
	}),
	container: (provided, state) => ({
		...provided,
		width: "100%",
	}),
	option: (provided, state) => ({
		...provided,
		width: "100%",
		padding: ".5rem 2rem",
		textTransform: "capitalize",
		color: state.isSelected ? "#fff" : "#000",
		backgroundColor: "#fff",
		backgroundColor: state.isSelected ? 'none' : "#fff",
		cursor: "pointer",
		width: "100%",
		"&:hover": {
			backgroundColor: 'none',
			color: "#fff",
		},
	}),
	control: (provided, state) => ({
		...provided,
		backgroundColor: "#fff",
		display: "flex",
		color: "#000",
		border: "none",
		padding: ".6rem",
		// // borderRadius: borderRadius,
		outline: state.isFocused ? "none" : "none",
		boxShadow: "none",
		cursor: "pointer",
		minHeight: "25px",
		boxShadow: "0px 1px 3px 0px #AEC0CFB5",
	}),
	// control: (base) => ({
	// 	...base,
	// 	height: 25,
	// 	minHeight: 25,
	// }),
	clearIndicator: (styles) => ({
		...styles,
		paddingTop: 7,
		paddingBottom: 7,
	}),
	indicatorContainer: (provided, state) => ({
		...provided,
		padding: "0 !important",
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		primary: "white",
		border: "none",
		paddingTop: 4,
		paddingBottom: 4,
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: "#000",
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: "#000",
	}),
	input: (provided, state) => ({
		...provided,
		color: "#000",
	}),
}

export const customStyles2 = {
	menu: (provided, state) => ({
		...provided,
		zIndex: 999,
		width: "100%",
		// // borderRadius: borderRadius,
		left: 0,
	}),
	valueContainer: (provided, state) => ({
		...provided,
		padding: 0,
		paddingInlineStart: "0rem",
	}),
	indicatorSeparator: () => ({
		display: "none",
	}),
	menuList: (provided) => ({
		...provided,
		padding: "1rem 0",
		width: "100%",
		boxShadow: "none",
		border: "none",
		backgroundColor: "transparent",
		// // borderRadius: borderRadius,
	}),
	container: (provided, state) => ({
		...provided,
		width: "100%",
		padding: "0 1rem",
	}),
	option: (provided, state) => ({
		...provided,
		width: "100%",
		padding: ".5rem 2rem",
		textTransform: "capitalize",
		color: state.isSelected ? "#fff" : "#000",
		backgroundColor: "#fff",
		backgroundColor: state.isSelected ? 'none' : "#fff",
		cursor: "pointer",
		width: "100%",
		fontSize: "1rem",
	}),
	control: (provided, state) => ({
		...provided,
		backgroundColor: "#fff",
		display: "flex",
		color: "#000",
		border: "none",
		padding: "0rem",
		paddingInlineStart: "0",
		// // borderRadius: borderRadius,
		outline: state.isFocused ? "none" : "none",
		boxShadow: "none",
		cursor: "pointer",
		width: "100%",
		height: "3rem",
		// boxShadow: '0px 7px 37px 0px #AEC0CFB5',
	}),

	dropdownIndicator: (provided, state) => ({
		...provided,
		primary: "white",
		border: "none",
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: "#000",
		fontSize: "1rem",
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: "#000",
		fontSize: "1rem",
	}),
	input: (provided, state) => ({
		...provided,
		color: "#000",
		fontSize: "1rem",
	}),
}

export const customStyles3 = {
	menu: (provided, state) => ({
		...provided,
		zIndex: 999,
		width: "100%",
		// // borderRadius: borderRadius,
		left: 0,
	}),
	valueContainer: (provided, state) => ({
		...provided,
		padding: 0,
		paddingInlineStart: "1rem",
	}),
	indicatorSeparator: () => ({
		display: "none",
	}),
	menuList: (provided) => ({
		...provided,
		padding: "1rem 0",
		width: "100%",
		boxShadow: "none",
		border: "none",
		backgroundColor: "transparent",
		// // borderRadius: borderRadius,
	}),
	container: (provided, state) => ({
		...provided,
		width: "100%",
		padding: "0 1rem",
	}),
	option: (provided, state) => ({
		...provided,
		width: "100%",
		padding: ".5rem 2rem",
		textTransform: "capitalize",
		color: state.isSelected ? "#fff" : "#000",
		backgroundColor: "#fff",
		backgroundColor: state.isSelected ? 'none' : "#fff",
		cursor: "pointer",
		width: "100%",
		fontSize: "1rem",
		"&:hover": {
			backgroundColor: 'none',
			color: "#fff",
		},
	}),
	control: (provided, state) => ({
		...provided,
		backgroundColor: "#fff",
		display: "flex",
		color: "#000",
		border: "none",
		padding: "0rem",
		paddingInlineStart: "0",
		// // borderRadius: borderRadius,
		outline: state.isFocused ? "none" : "none",
		boxShadow: "none",
		cursor: "pointer",
		width: "100%",
		height: "3rem",
		// boxShadow: '0px 7px 37px 0px #AEC0CFB5',
	}),

	dropdownIndicator: (provided, state) => ({
		...provided,
		primary: "white",
		border: "none",
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: "#000",
		fontSize: "1rem",
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: "#000",
		fontSize: "1rem",
	}),
	input: (provided, state) => ({
		...provided,
		color: "#000",
		fontSize: "1rem",
	}),
}

