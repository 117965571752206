import React, { useContext, useEffect, useRef, useState } from 'react'
import Select from "react-select"
import Button from '../common/Button'
import { CustomStyles } from '../common/FormInputs'
import { useTranslation } from "next-i18next"
import { useRouter } from 'next/router';
import { nextRouteContext } from '../../contexts/nextRouteContext';
import { isMobileContext } from '../../contexts/isMobileContext'
import { isMenuOpendContext } from '@/contexts/isMenuOpendContext'

const SearchInputs = ({
    isSearchMenuOpened,
    menu,
    projectsTabs,
    toggleMobileSearchMenu
}) => {
    const router = useRouter()
    const { t } = useTranslation('common')
    const { setNextRoute } = useContext(nextRouteContext)
    const { isMobile } = useContext(isMobileContext)
    const [destinationOptions, setDestinationOptions] = useState()
    const [allProjects, setAllProjects] = useState()
    const [destinationError, setDestinationError] = useState(false)
    const [projectError, setProjectError] = useState(false)
    const [projectOptions, setProjectOptions] = useState()
    const [phaseOptions, setPhaseOptions] = useState()
    const [projectSelect, setProjectSelect] = useState(null)
    const [projectSelectData, setProjectSelectData] = useState(null)
    const [phaseSelect, setPhaseSelect] = useState(null)
    const [projectNameSelect, setNameProjectSelect] = useState(null)
    const [phaseNameSelect, setNamePhaseSelect] = useState(null)
    const [destinationSelect, setDestinationSelect] = useState(null)
    const projectRef = useRef()
    const phaseRef = useRef()
    const {  setIsBookingMenuOpened,setSearchMenuOpened } = useContext(isMenuOpendContext)
    //fill destination options
    useEffect(() => {
        const options = []
        const projects = []
        projectsTabs?.map((tab, index) => {
            projects.push(...tab.attributes.projects.data)
            options.push({
                label: tab.attributes.name,
                value: index
            })
        })
        setDestinationOptions(options)
        setAllProjects(projects)
    }, [projectsTabs])

    //fill projects options
    const destinationHandler = (value) => {
        setDestinationSelect(value)
        setDestinationError(false)
        setProjectError(false)
        setProjectSelectData(null)
        projectRef.current.clearValue()
        const options = [];
        // projectsTabs?.map(tab => {
        //     tab.attributes.name == value &&
        (
            projectsTabs[value].attributes.projects.data.sort((a, b) => a.attributes.Order - b.attributes.Order).map((project) => {
                options.push({
                    label: project.attributes.Project_Name,
                    value: project.attributes.Slug,
                })
            }),
            projectsTabs[value].attributes.business_projects.data.sort((a, b) => a.attributes.Order - b.attributes.Order).map((project) => {
                options.push({
                    label: project.attributes.Project_Name,
                    value: project.attributes.Slug,
                })
            }),
            projectsTabs[value].attributes.commercial_projects.data.sort((a, b) => a.attributes.Order - b.attributes.Order).map((project) => {
                options.push({
                    label: project.attributes.Project_Name,
                    value: project.attributes.Slug,
                })
            })
        )
        // })
        setProjectOptions(options)
    }

    //get data of selected project
    const projectHandler = (value, label) => {
        phaseRef.current.clearValue()
        setProjectError(false)
        setProjectSelect(value)
        setNameProjectSelect(label)
        //to remove last phases and add new after new select
        setProjectSelectData(null)
        // const options = [];
        allProjects?.map(project => {
            project.attributes.Slug === value &&
                setProjectSelectData(project?.attributes?.phases?.data)
            // project.attributes.phases.data.sort((a, b) => a.attributes.Order - b.attributes.Order).map((phase) => {
            //     options.push({
            //         label: phase.attributes.Phase_Name,
            //         value: phase.attributes.slug,
            //     })
            // })
        })
        // setPhaseOptions([...new Map(options?.map(item => [item["value"], item])).values()])
    }

    //fill phase options from selected project
    useEffect(() => {
        const options = [];
        projectSelectData?.sort((a, b) => a.attributes.Order - b.attributes.Order).map((phase) => {
            // phase.attributes.slug!=null&&
            options.push({
                label: phase.attributes.Phase_Name,
                value: phase.attributes.slug,
            })
        })
        // setPhaseOptions([...new Map(options?.map(item => [item["value"], item])).values()])
        setPhaseOptions([...options])

    }, [projectSelectData])

    //search-button-home
    const handleSearch = () => {
        if (isMobile) {
            window.scrollTo({ top: 0, behavior: 'smooth', })
            setSearchMenuOpened(true)
        } else {
            destinationSelect == null ? (setDestinationError(true), setProjectError(true)) :
                projectSelect != null ? phaseSelect == null ?
                    (router.push(`/projects/${projectSelect}`),
                        setNextRoute(projectNameSelect),
                        phaseNameSelect && localStorage.setItem("phase", phaseNameSelect)
                    )
                    : (router.push(`/projects/${projectSelect}/${phaseSelect}`),
                        setNextRoute(phaseNameSelect))
                    : setProjectError(true)
        }

    }

    //search-button-menu
    const handleSearchMenu = () => {
        destinationSelect == null ? (setDestinationError(true), setProjectError(true)) :
            projectSelect != null ? phaseSelect == null ? (toggleMobileSearchMenu(!isSearchMenuOpened),
                router.push(`/projects/${projectSelect}`), setNextRoute(projectNameSelect),
                phaseNameSelect && localStorage.setItem("phase", phaseNameSelect)
            ) : (toggleMobileSearchMenu(!isSearchMenuOpened), router.push(`/projects/${projectSelect}/${phaseSelect}`), setNextRoute(phaseNameSelect)) : setProjectError(true)
    }

    return (
        <div className='search-section bold'>
            <div className='search-input' >
                <label htmlFor='area-input'>{`${t("search.destination")}`}</label>
                <Select
                    instanceId={'search area'}
                    options={destinationOptions}
                    isSearchable={false}
                    onChange={(e) => { destinationHandler(e.value) }}
                    placeholder={`${t("search.destination")}`}
                    className="select-drop-down"
                    styles={CustomStyles}
                    noOptionsMessage={() => t('search.no_options')}
                    id={'area-input'}
                    aria-labelledby={'area-input'}
                    aria-label={'area-input'}
                />
                {destinationError && <span>{t('search.required')}</span>}
            </div>
            <div className='search-input' >
                <label htmlFor='project-input'>{`${t("search.project")}`}</label>
                <Select
                    isSearchable={false}
                    options={projectOptions}
                    onChange={(e) => { projectHandler(e?.value, e?.label) }}
                    placeholder={`${t("search.project")}`}
                    className="select-drop-down"
                    styles={CustomStyles}
                    instanceId={'search project'}
                    id={'project-input'}
                    ref={projectRef}
                    noOptionsMessage={() => t('search.no_options')}
                    aria-labelledby={'project-input'}
                    aria-label={'project-input'}
                />
                {projectError && <span>{t('search.required')}</span>}
            </div>
            <div className='search-input' >
                <label htmlFor='type-input'>{`${t("search.property_type")}`}</label>
                <Select
                    isSearchable={false}
                    instanceId={'search type'}
                    id={'type-input'}
                    options={phaseOptions}
                    ref={phaseRef}
                    onChange={(e) => { setPhaseSelect(e?.value), setNamePhaseSelect(e?.label) }}
                    placeholder={`${t("search.property_type")}`}
                    className="select-drop-down"
                    styles={CustomStyles}
                    noOptionsMessage={() => t('search.no_options')}
                    aria-labelledby={'type-input'}
                    aria-label={'type-input'}
                />
            </div>
            <div className='search-button' onClick={() => menu ? handleSearchMenu() : handleSearch()}>
                <Button text={`${t("search.search")}`} blackStyle />
            </div>
            {isMobile && !menu && <div className='search-button' onClick={() => setIsBookingMenuOpened(true)}>
                <Button text={`${t("common.book_now")}`} whiteBorder />
            </div>}
        </div>
    )
}

export default SearchInputs