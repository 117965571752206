import { createContext, useEffect, useState } from "react"

//create a context, with createContext api
export const phaseColorContext = createContext()

function PhaseColor(props) {
	const [phaseColor,setPhaseColor] = useState()

	return (
		<>
			<phaseColorContext.Provider
				value={{
					phaseColor,setPhaseColor
				}}
			>
				{props.children}
			</phaseColorContext.Provider>
		</>
	)
}

export default PhaseColor
