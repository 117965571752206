import { createContext, useEffect, useState } from "react"

//create a context, with createContext api
export const nextRouteContext = createContext()

function NextRoute(props) {
	const [nextRoute, setNextRoute] = useState(false)
	const [routeChange, setRouteChange] = useState()
	const [isWebsiteLoading,setIsWebsiteLoading]=useState(true)

	return (
		<>
			<nextRouteContext.Provider
				value={{
					isWebsiteLoading,setIsWebsiteLoading,
					nextRoute, setNextRoute,
					routeChange, setRouteChange
				}}
			>
				{props.children}
			</nextRouteContext.Provider>
		</>
	)
}

export default NextRoute
