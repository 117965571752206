import React, { useContext, useEffect, useState } from "react"
import * as Yup from "yup"
import { ErrorMessage, useFormik } from "formik"
import { useTranslation } from "next-i18next"
import { isMobileContext } from "../../contexts/isMobileContext"
import { API_URL } from "../../utils/FetchData"
import ArrowsSpliting from "../Animations/ArrowsSpliting"
const FooterForm = () => {
	const { t } = useTranslation("common")
	const { isMobile } = useContext(isMobileContext)
	const [isSubmit, setIsSubmit] = useState(false)
	const [isSending, setIsSending] = useState(false)

	const validationSchema = Yup.object({
		email: Yup.string()
			.email(t("footer.invalid"))
			.required(t("footer.required"))
			.matches(/^([a-zA-Z0-9_\-\.])/, t("footer.invalid")),
	})

	const onSubmit = async (values, { resetForm }) => {
		setIsSending(true)
		const data = { data: values }
		let deviceType = /iPad/.test(navigator.userAgent)
			? "t"
			: /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
					navigator.userAgent
			  )
			? "m"
			: "d"
		fetch(`${API_URL}/news-later-forms`, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		})
			.then((resp) =>
				resp.status == 200
					? (setIsSubmit(true),
					  setIsSending(false),
					  resetForm(),
					  window.gtag("event", "conversion", {
							send_to: "AW-11303956860/o0BMCIjcu9MYEPziko4q",
							email: values.email,
							Type: "newsletter",
					  }),
					  window.criteo_q.push(
							{ event: "setAccount", account: 107694 },
							{
								event: "setEmail",
								email: values.email,
							},
							{
								event: "setEmail",
								email: values.email,
								hash_method: "sha256",
							},
							{ event: "setSiteType", type: deviceType },
							{
								event: "setEmail",
								email: values.email,
								hash_method: "md5",
							}
					  ))
					: ""
			)
			.catch((error) => {
				console.log(error)
			})
	}

	const initialValues = {
		email: "",
	}

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
	})
	return (
		<form
			onSubmit={formik.handleSubmit}
			className={` _eleX${
				formik.touched.email && formik.errors.email ? "form-error" : ""
			} form`}
		>
			<div className="input-group">
				<span className="bold">{t("footer.newsletter")}</span>
				<label>email</label>
				<input
					type="text"
					placeholder={
						isSubmit ? "Thank you for subscribe" : `${t("footer.enter_email")}`
					}
					name="email"
					disabled={isSubmit || isSending}
					className={`form-control medium`}
					id="exampleFormControlInput1"
					{...formik.getFieldProps("email")}
				/>
				{formik.touched.email && formik.errors.email && (
					<span className="input-error">{formik.errors.email}</span>
				)}
			</div>
			<div>
				<button
					className={`${
						!isMobile && "button--isi"
					} button-section white-button ${isSubmit && "submitted"}`}
					type="submit"
					disabled={isSubmit || isSending}
				>
					<span>
						<ArrowsSpliting>
							{isSending
								? "Sending..."
								: !isSubmit
								? `${t("footer.subscribe")}`
								: "SUBSCRIBED"}
						</ArrowsSpliting>
					</span>
				</button>
			</div>
		</form>
	)
}

export default FooterForm
