/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import { animateScroll as scroll, scroller } from "react-scroll"
import { isMobileContext } from '../../contexts/isMobileContext'
import { isStickyContext } from '../../contexts/isSticky'
import StickyNav from './StickyNav'

export default function StickyNavComponent({ tabs }) {
  const [isVisible, setIsVisible] = useState(false)
  const { isMobile } = useContext(isMobileContext)
  const { isSticky, setIsSticky } = useContext(isStickyContext)
  let lastScrollTop = 0;
  const scrollTop = () => {
    scroll.scrollToTop()
  }
  const handleScroll = () => {
    scrollTop()
  }

  useEffect(() => {
    const listenToScroll = () => {
      const winScroll = window.pageYOffset || document.documentElement.scrollTop
      if (winScroll > lastScrollTop) {
        // !isVisible && // to limit setting state only the first time
        setIsVisible(false)
        setIsSticky(false)

        // Pin bar while scrolling
        // setIsVisible(true)
        // setIsSticky(true)
      }
      else if (window.pageYOffset <= 100) {
        setIsVisible(false)
        setIsSticky(false)
      }
      else {
        setIsVisible(true)
        setIsSticky(true)
      }
      lastScrollTop = winScroll <= 0 ? 0 : winScroll
    }
    window.addEventListener("scroll", (e)=>{listenToScroll()
      ,e.preventDefault()},
    // {passive: true}
    )
    return () => window.removeEventListener("scroll", listenToScroll)
  }, [])

  return (
    <>
      <AnimatePresence>
        {isVisible && <motion.div className='stickybar'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0,
              duration: .25
            },
          }}
          transition={ {
            delay: 0,
            duration: .25
          }
          }
        >
          <StickyNav tabs={tabs} />
        </motion.div>
        }
      </AnimatePresence>
    </>
  )
}
