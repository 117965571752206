import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useContext, useEffect } from 'react'
import { isMobileContext } from '../../contexts/isMobileContext'
import LangSwitcher from './LangSwitcher'
import { AnimatePresence, useCycle } from 'framer-motion'
import MobileSearchMenu from './MobileSearchMenu'
import { useTranslation } from "next-i18next"
import { phaseColorContext } from '../../contexts/phaseColorContext'
import { isMenuOpendContext } from '../../contexts/isMenuOpendContext'
import ArrowsSpliting from '../Animations/ArrowsSpliting'
const SubNav = () => {
    const { t } = useTranslation('common')
    const { locale } = useRouter()
    const { phaseColor } = useContext(phaseColorContext);
    const { isMobile } = useContext(isMobileContext)
    const {  searchMenuOpened, setSearchMenuOpened, setIsBookingMenuOpened,setIsMenuOpenedContext } = useContext(isMenuOpendContext)
    const [isSearchMenuOpened, toggleMobileSearchMenu] = useCycle(false, true)
    const toggleMobileSearchMenuOpen = () => {
        document.body.classList.toggle("no-scroll")
        toggleMobileSearchMenu(!isSearchMenuOpened)
        setIsMenuOpenedContext(false)
    }
    useEffect(() => {
        if (isSearchMenuOpened) {
            document.body.classList.add("no-scroll")
        } else {
            document.body.classList.remove("no-scroll")
        }
    }, [isSearchMenuOpened])
    useEffect(() => {
        searchMenuOpened && toggleMobileSearchMenuOpen(); setSearchMenuOpened(false)
    }, [searchMenuOpened])
    return (
        <>
            <div className={`subnav-container`} style={{ background: phaseColor ? phaseColor : '#31353d' }}>
                <LangSwitcher>
                    <div className="lang-switcher">{`${t("navbar.lang")}`}</div>
                </LangSwitcher>
                <div className='contact-container'> 
                    <a href="tel://16696" target="_blank" rel="noreferrer" aria-label="number">
                        <div className='phone' style={{ background: isMobile ? `${phaseColor ? phaseColor : '#292c33'}` : `${phaseColor ? phaseColor : '#31353d'}`, filter: isMobile ? "brightness(0.85)" : 'unset' }}>
                            <div className='icon-img'>
                                <Image
                                    src={'/icons/phoneNav.svg'}
                                    alt="icon"
                                    objectFit="contain"
                                    layout="fill"
                                    priority
                                />
                            </div>
                            {!isMobile &&
                                <span>
                                    <ArrowsSpliting>
                                        16696
                                    </ArrowsSpliting>
                                </span>
                            }
                        </div>
                    </a>
                    <div className={`search ${isMobile && 'icon'}`} onClick={() => { toggleMobileSearchMenuOpen() }} style={{ background: `${phaseColor}`, filter: "brightness(0.85)" }}>
                        <div className='icon-img'>
                            <Image
                                src={'/icons/search.svg'}
                                alt="icon"
                                objectFit="contain"
                                layout="fill"
                            />
                        </div>
                        {!isMobile && <span>
                            <ArrowsSpliting>{`${t("navbar.search")}`}</ArrowsSpliting></span>}
                    </div>
                    {isMobile && <div className='search booknow' onClick={() => setIsBookingMenuOpened(true)}>
                        <span>
                            <ArrowsSpliting>{`${t("common.book_now")}`}</ArrowsSpliting>
                        </span>
                    </div>}
                </div>
            </div>
            <AnimatePresence>
                {isSearchMenuOpened && <MobileSearchMenu isSearchMenuOpened={isSearchMenuOpened} toggleMobileSearchMenu={toggleMobileSearchMenu} />}
            </AnimatePresence>
        </>
    )
}

export default SubNav