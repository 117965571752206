import { Select } from "react-select"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { AnimatePresence, motion } from "framer-motion"
import * as Yup from "yup"
import React, { useContext } from "react"
import { isMobileContext } from "../../contexts/isMobileContext"
import Image from "next/legacy/image"
import { API_URL } from "../../utils/FetchData"
import { useState } from "react"
import { useTranslation } from "next-i18next"
import ArrowsSpliting from "../Animations/ArrowsSpliting"
import FadeIn from "../Animations/FadeIn"
import { useEffect } from "react"

export default function ComplainForm() {
	const { isMobile } = useContext(isMobileContext)
	const [formSubmitted, setFormSubmitted] = useState(false)
	const { t } = useTranslation("common")
	const [isSending, setIsSending] = useState(false)
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
	const validationSchema = Yup.object({
		Name: Yup.string()
			.required(t("enquire.required"))
			.matches(/^[^ ]\S*/, t("enquire.required")),
		Email: Yup.string()
			.email(t("enquire.invalid_format"))
			.required(t("enquire.required"))
			.matches(/^([a-zA-Z0-9_\-\.])/, t("enquire.invalid_format")),
		Phone: Yup.string()
			.required(t("enquire.required"))
			.matches(phoneRegExp, t("enquire.invalid_phone"))
			.max(10, t("enquire.invalid_phone"))
			.min(10, t("enquire.invalid_phone")),
		Message: Yup.string()
			.required(t("enquire.required"))
			.matches(/^[^ ]\S*/, t("enquire.required")),
	})

	const initialValues = {
		Name: "",
		Email: "",
		Phone: "",
		Message: "",
	}
	useEffect(() => {
		let timeOut = ""
		if (formSubmitted) {
			timeOut = setTimeout(() => {
				document.querySelector("#complainForm").classList.remove("show"),
					document.body.classList.remove("no-scroll")
			}, 2000)
		}
		return () => clearTimeout(timeOut)
	}, [formSubmitted])
	return (
		<div className="complain-form" id="complainForm">
			<div className="container">
				<FadeIn customClass="header">
					<h2 className="_eleX">{t("enquire.complain_header")}</h2>
				</FadeIn>
				<FadeIn customClass="form-container">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values, { resetForm }) => {
							setIsSending(true)
							const data = { data: values }
							fetch(`${API_URL}/customer-complaints`, {
								method: "POST",
								body: JSON.stringify(data),
								headers: {
									"Content-type": "application/json; charset=UTF-8",
								},
							})
								.then((response) => {
									if (response.status == 200) {
										setIsSending(false)
										resetForm()
										setFormSubmitted(true)
										window.gtag("event", "conversion", {
											send_to: "AW-11303956860/o0BMCIjcu9MYEPziko4q",
											Name: values.Name,
											Email: values.Email,
											Phone: values.Phone,
											Message: values.Message,
											Type: "complain",
										})
										let deviceType = /iPad/.test(navigator.userAgent)
											? "t"
											: /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
													navigator.userAgent
											  )
											? "m"
											: "d"
										window.criteo_q.push(
											{ event: "setAccount", account: 107694 },
											{
												event: "setEmail",
												email: values.email,
											},
											{
												event: "setEmail",
												email: values.email,
												hash_method: "sha256",
											},
											{
												event: "setEmail",
												email: values.email,
												hash_method: "md5",
											},
											{ event: "setSiteType", type: deviceType },
											{
												event: "trackTransaction",
												id: Math.floor(Math.random() * 99999999999) + 1,
												item: [{ id: "1", price: "1", quantity: "1" }],
											}
										)
									}
								})
								.catch((error) => {
									console.log(error, "error")
								})
						}}
					>
						{(formProps) => (
							<Form>
								{!formSubmitted ? (
									<div className="form-wrapper">
										<div className="field-wrapper _eleX">
											<label className="file-input__label" htmlFor="fullName">
												{t("enquire.firstName")}
											</label>
											<div className="input-wrapper">
												<Field
													disabled={formSubmitted || isSending}
													placeholder={t("enquire.firstName")}
													name="Name"
													className={"text-input"}
												/>
											</div>
											<p className="errorMsg">
												<ErrorMessage name="Name" />
											</p>
										</div>
										<div className="field-wrapper _eleX">
											<label className="file-input__label" htmlFor="Email">
												{t("enquire.email")}
											</label>
											<div className="input-wrapper">
												<Field
													disabled={formSubmitted || isSending}
													placeholder={t("enquire.email")}
													name="Email"
													className={"text-input"}
												/>
											</div>
											<p className="errorMsg">
												<ErrorMessage name="Email" />
											</p>
										</div>
										<div className="field-wrapper _eleX">
											<label className="file-input__label" htmlFor="Phone">
												{t("enquire.phone")}
											</label>
											<div className="input-wrapper">
												<Field
													disabled={formSubmitted || isSending}
													placeholder={t("enquire.phone")}
													name="Phone"
													className={"text-input"}
													type="number"
												/>
											</div>

											<p className="errorMsg">
												<ErrorMessage name="Phone" />
											</p>
										</div>
										<div className="field-wrapper _eleX">
											<label className="file-input__label" htmlFor="Message">
												{t("enquire.complain_subject")}
											</label>

											<div className="input-wrapper">
												<Field
													disabled={formSubmitted || isSending}
													placeholder={t("enquire.complain_subject")}
													name="Message"
													className={"text-input"}
													as="textarea"
													rows="3"
												/>
											</div>

											<p className="errorMsg">
												<ErrorMessage name="Message" />
											</p>
										</div>
										<button
											type="submit"
											className={`${!isMobile && "button--isi"} ${
												formSubmitted && "formSubmitted"
											} button-section _eleX`}
											disabled={formSubmitted && true}
										>
											<span>
												<ArrowsSpliting>
													{isSending
														? "Sending..."
														: formSubmitted
														? t("enquire.thank_you")
														: t("enquire.send")}
												</ArrowsSpliting>
											</span>
										</button>
									</div>
								) : (
									<AnimatePresence>
										<motion.div
											initial={{ opacity: 0, x: 30 }}
											whileInView={{ opacity: 1, x: 0 }}
											transition={{ delay: 0.1, duration: 0.5 }}
											viewport={{ once: true }}
											className="thank-message"
										>
											<h2 className="_eleY">Thank You for reaching out!</h2>
											<h2 className="_eleY">We will contact you shortly.</h2>
										</motion.div>
									</AnimatePresence>
								)}
							</Form>
						)}
					</Formik>
				</FadeIn>
			</div>
			<div
				className="cancel-btn"
				onClick={() => {
					document.querySelector("#complainForm").classList.remove("show"),
						document.body.classList.remove("no-scroll")
				}}
			>
				<svg
					clipRule="evenodd"
					fillRule="evenodd"
					strokeLinejoin="round"
					strokeMiterlimit="2"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" />
				</svg>
			</div>
			<div className="img-container">
				<Image
					src="/icons/newLogo.png"
					priority
					objectFit="contain"
					layout="fill"
					alt="logo_img"
				/>
			</div>
		</div>
	)
}
