import "../styles/app.scss"
import "../styles/App-rtl.scss"
import Head from "next/head"
import Script from "next/script"
import { useState } from "react"
import { Hydrate, QueryClient, QueryClientProvider } from "react-query"
import NextRoute from "../contexts/nextRouteContext"
import { ReactQueryDevtools } from "react-query/devtools"
import Layout from "../components/common/Layout"
import { AnimatePresence } from "framer-motion"
import { appWithTranslation } from "next-i18next"
import { ChakraProvider } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import ComplainForm from "../components/common/ComplainForm"
import { DefaultSeo } from "next-seo"
import { useEffect } from "react"

function MyApp({ Component, pageProps }) {
	const { locale } = useRouter()
	const [queryClient] = useState(() => new QueryClient())

	useEffect(() => {
		window.document.body.style.overflowX = "hidden"
		let dir = locale == "ar" ? "rtl" : "ltr"
		document.querySelector("html").setAttribute("dir", dir)
	  }, [locale])
	return (
		<>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			</Head>
			<DefaultSeo
				title={"Hyde Park Developments"}
				description={
					"Hyde Park Developments is a leading private residential, leisure, and commercial developer in Egypt."
				}
				canonical={`https://www.hydeparkdevelopments.com/`}
				openGraph={{
					type: "website",
					url: "https://www.hydeparkdevelopments.com",
					images: [{ url: "/whoWe/banner.png" }],
					siteName: "Hyde Park Developments",
				}}
			/>

			<QueryClientProvider client={queryClient}>
				<Hydrate state={pageProps.dehydratedState}>
					<NextRoute>
						<Layout>
							<Script
								async
								src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ADS}`}
							></Script>
							<Script id="google-ads" strategy="lazyOnload">
								{`
							window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} 
						 	gtag('js', new Date()); gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ADS}'); 
							`}
							</Script>
							<Script
								async
								src="https://www.googletagmanager.com/gtag/js?id=G-NE089KS02D"
							></Script>
							<Script id="google-ads" strategy="lazyOnload">
								{`
							window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} 
						 	gtag('js', new Date()); gtag('config', 'G-NE089KS02D'); 
							`}
							</Script>
							<Script
								async
								src="https://www.googletagmanager.com/gtag/js?id=AW-11303956860"
							></Script>
							<Script id="google-ads" strategy="lazyOnload">
								{`
							window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} 
						 	gtag('js', new Date()); gtag('config', 'AW-11303956860'); 
							`}
							</Script>
							{/* <!-- Criteo Loader File --> */}
							{/* <Script
								strategy="afterInteractive"
								src="//dynamic.criteo.com/js/ld/ld.js?a=105688"
								async="true"
							></Script> */}

							{/* <!-- Criteo Loader File --> */}
							<Script
								strategy="afterInteractive"
								src="//dynamic.criteo.com/js/ld/ld.js?a=107694"
								async="true"
							></Script>
							{/* <!-- END Criteo Loader File --> */}
							{/* <!-- Criteo Homepage Tag --> */}
							<Script id="criteo-homepage" strategy="afterInteractive">
								{`
							window.criteo_q = window.criteo_q || [];
							var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
							`}
							</Script>
							{/* <!-- END Criteo Homepage Tag --> */}
							<AnimatePresence
								mode="wait"
								initial={false}
								onExitComplete={() => window.scrollTo(0, 0)}
							>
								<ChakraProvider>
									<Component {...pageProps} key={Math.random()} />
								</ChakraProvider>
							</AnimatePresence>
							{/* <StickyNavComponent /> */}
							<ComplainForm />
						</Layout>
					</NextRoute>
				</Hydrate>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</>
	)
}

export default appWithTranslation(MyApp)
export async function getServerSideProps({ locale }) {
	return {
		props: {
			...(await serverSideTranslations(locale, ["common"])),
		},
	}
}
