import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion";
import { useQuery } from "react-query"
import { useRouter } from 'next/router';
import { fetchData } from '../../utils/FetchData';
import SearchInputs from './SearchInputs';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import FadeIn from '../Animations/FadeIn';
const MobileSearchMenu = ({ isSearchMenuOpened, toggleMobileSearchMenu }) => {
    const router = useRouter()
    const { t } = useTranslation("common")
    const [projectsTabs, setProjectsTabs] = useState()
    const { data, isSuccess } = useQuery(
        ["searchData", router.locale],
        () =>
            fetchData(
                "home",
                router.locale,
                "populate[1]=Our_Portfolio.Project_Tabs.projects&populate[2]=Our_Portfolio.Project_Tabs.business_projects&populate[3]=Our_Portfolio.Project_Tabs.commercial_projects&populate[4]=Our_Portfolio.Project_Tabs.projects.phases"
            )
    )

    useEffect(() => {
        isSuccess && setProjectsTabs(data?.data?.attributes.Our_Portfolio?.Project_Tabs?.data)
    },
        [data, isSuccess])

    return (
        <>
            <motion.div className='mobile-search-menu'
                initial={{ width: 0 }}
                animate={{
                    width: "100vw",
                    transition: {
                        type: "spring",
                        stiffness: 400,
                        damping: 40,
                    },
                }}
                exit={{
                    x: router.locale == "en" ? 500 : -500,
                    width: 0,
                    transition: {
                        delay: 0,
                        type: "spring",
                        stiffness: 400,
                        damping: 40,

                    },
                }}
            >
                {isSearchMenuOpened && <div className={`burger-menu-container`} onClick={() => { toggleMobileSearchMenu(true) }}>
                    <div className='burger-border'>
                        <div className={`burger-menu`} >
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>}
                <div className="img-container">
                    <div className="overlay"></div>
                    <Image
                        src="/contact/banner.png"
                        alt="search img"
                        layout='fill'
                        objectFit='cover'
                    />
                </div>
                {isSearchMenuOpened &&
                    <div className='title'>
                        <FadeIn>
                            <h6 className='_eleX title'>{`${t("navbar.search")}`}</h6>
                        </FadeIn>
                    </div>
                }
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1.5 }}
                >
                    <SearchInputs
                        projectsTabs={projectsTabs}
                        toggleMobileSearchMenu={toggleMobileSearchMenu}
                        menu
                    />
                </motion.div>
            </motion.div>
        </>
    )
}

export default MobileSearchMenu