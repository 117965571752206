import { createContext, useEffect, useState } from "react"

//create a context, with createContext api
export const isStickyContext = createContext()

function IsSticky(props) {
	const [isSticky, setIsSticky] = useState(false)

	return (
		<>
			<isStickyContext.Provider
				value={{
					isSticky, setIsSticky
				}}
			>
				{props.children}
			</isStickyContext.Provider>
		</>
	)
}

export default IsSticky
