import { motion } from "framer-motion"
import IsMobileProvider from "../../contexts/isMobileContext"
import { PageAnimate } from "../../utils/Animations"
import { useRouter } from "next/router"
import Navbar from "./Navbar"
import Footer from "./Footer"
import IsSticky from "../../contexts/isSticky"
import NextRoute from "../../contexts/nextRouteContext"
import PhaseColor from "../../contexts/phaseColorContext"
import IsMenuOpendProvider from "../../contexts/isMenuOpendContext"
import gsap from "gsap"
export default function Layout({ children }) {
	gsap.config({ nullTargetWarn: false });
	const router = useRouter()
	return (
		<>
			<IsMobileProvider>
				<IsMenuOpendProvider>
					<IsSticky>
						<PhaseColor>
							<motion.main
								initial="hidden"
								animate="enter"
								exit="exit"
								transition={{ type: "linear" }}
								variants={PageAnimate}
							>
								<Navbar />
								{children}
								{/* <Footer /> */}
							</motion.main>
						</PhaseColor>
					</IsSticky>
				</IsMenuOpendProvider>
			</IsMobileProvider>
		</>
	)
}
