import { isMenuOpendContext } from "@/contexts/isMenuOpendContext"
import Image from "next/image"
import { useRouter } from "next/router"

import React, { forwardRef, useContext, useEffect, useState } from "react"
import { ErrorMessage, useFormik, Formik, Form, Field } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import { isMobileContext } from "../../contexts/isMobileContext"
import { API_URL } from "../../utils/FetchData"
import { motion, AnimatePresence } from "framer-motion"
import { useTranslation } from "next-i18next"
import ArrowsSpliting from "../Animations/ArrowsSpliting"
import FadeIn from "../Animations/FadeIn"

const BookingMenu = ({ tabs }) => {
	const router = useRouter()
	const { isBookingMenuOpened, setIsBookingMenuOpened } =
		useContext(isMenuOpendContext)
	const { isMobile } = useContext(isMobileContext)
	const [formSubmitted, setFormSubmitted] = useState(false)
	const [isSending, setIsSending] = useState(false)
	const [projects, setProjects] = useState([])
	const { t } = useTranslation("common")
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
	const customProjStyles = {
		menu: (provided, state) => ({
			...provided,
			color: "#5f5f5f",
			padding: "0",
			zIndex: "2",
			fontSize: isMobile ? ".75rem" : "1rem",
			postion: "relative",
		}),
		menuList: (provided, state) => ({
			...provided,
			padding: "0",
			"> .css-1n7v3ny-option": {
				backgroundColor: "red",
			},
		}),
		control: (provided, state) => ({
			...provided,
			backgroundColor: "unset",
			boxShadow: "none",
			borderRadius: "0.25rem",
			height: isMobile ? "3rem" : "4rem",
			transition: "1s all",
			padding: "0",
			zIndex: "1",
			cursor: "pointer",
			backgroundColor: "#f8f8f8",
			color: `red`,
			border: "1px solid rgba(0, 0, 0, 0.1)",
			"&:focus-within": {
				borderColor: "rgba(49, 53, 61, 0.7)",
			},
		}),
		input: (provided, state) => ({
			...provided,
			padding: "0",
			height: "100%",
			width: "100%",
			margin: "0",
		}),
		placeholder: (provided, state) => ({
			...provided,
			color: "rgba(49, 53, 61,.4)",
		}),
		valueContainer: (provided, state) => ({
			...provided,
			padding: "0",
			paddingInlineStart: "0.75rem",
			fontSize: isMobile ? ".75rem" : "1rem",
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: "#000",
		}),
		indicatorSeparator: (provided, state) => ({
			...provided,
			display: "none",
		}),
		// option: (provided, state) => ({
		// 	...provided,
		// 	backgroundColor: state.isSelected
		// 		? phaseColor
		// 		: state.isFocused
		// 			? `${phaseColor}50`
		// 			: "white",
		// 	padding: 20,
		// 	color: state.isSelected ? `white` : "#000",
		// 	"&:hover": {
		// 		backgroundColor: state.isSelected ? phaseColor : isMobile ? "unset" : `${phaseColor}50`,
		// 	},
		// 	cursor: "pointer",
		// 	borderRadius: "0",
		// }),
		option: (provided, state) => ({
			...provided,
			cursor: "pointer",
			paddingInlineStart: "1rem",
			backgroundColor: state.isSelected
				? "#31353d"
				: state.isFocused
				? `#31353d50`
				: "white",
			color: state.isSelected ? `white` : "#000",
			"&:hover": {
				backgroundColor: state.isSelected
					? "#31353d"
					: isMobile
					? "unset"
					: `#31353d50`,
			},
		}),
	}
	useEffect(() => {
		const allProjects = []
		tabs?.map((tab, index) => {
			index != 0 &&
				tab?.attributes?.projects?.data?.map((project) => {
					allProjects.push({
						label: project?.attributes.Project_Name,
						value: project?.attributes.Slug,
					})
				})
			tab?.attributes?.business_projects?.data?.map((project) => {
				allProjects.push({
					label: project?.attributes.Project_Name,
					value: project?.attributes.Slug,
				})
			})
			tab?.attributes?.commercial_projects?.data?.map((project) => {
				allProjects.push({
					label: project?.attributes.Project_Name,
					value: project?.attributes.Slug,
				})
			})
		})
		setProjects([...allProjects])
	}, [tabs])

	const validationSchema = Yup.object({
		first_name: Yup.string()
			.required(t("enquire.required"))
			.matches(/^[^ ]\S*/, t("enquire.required")),
		last_name: Yup.string()
			.required(t("enquire.required"))
			.matches(/^[^ ]\S*/, t("enquire.required")),
		email: Yup.string()
			.email(t("enquire.invalid_format"))
			.required(t("enquire.required"))
			.matches(/^([a-zA-Z0-9_\-\.])/, t("enquire.invalid_format")),
		phone: Yup.string()
			.required(t("enquire.required"))
			.matches(phoneRegExp, t("enquire.invalid_phone"))
			.max(10, t("enquire.invalid_phone"))
			.min(10, t("enquire.invalid_phone")),
		project_name: Yup.string().required(t("enquire.required")),
	})

	const initialValues = {
		first_name: "",
		last_name: "",
		project_name: "",
		email: "",
		phone: "",
		phase: "",
		unit_type: "",
		office_type: "",
		message: "",
	}
	useEffect(() => {
		let timeOut = ""
		if (formSubmitted) {
			timeOut = setTimeout(() => {
				setIsBookingMenuOpened(false)
			}, 2000)
		}
		return () => clearTimeout(timeOut)
	}, [formSubmitted])
	return (
		<>
			<motion.div
				className="mobile-search-menu form"
				initial={{ width: 0 }}
				animate={{
					width: "100vw",
					transition: {
						type: "spring",
						stiffness: 400,
						damping: 40,
					},
				}}
				exit={{
					x: router.locale == "en" ? 500 : -500,
					width: 0,
					transition: {
						delay: 0,
						type: "spring",
						stiffness: 400,
						damping: 40,
					},
				}}
			>
				{isBookingMenuOpened && (
					<div
						className={`burger-menu-container`}
						onClick={() => {
							setIsBookingMenuOpened(false)
						}}
					>
						<div className="burger-border">
							<div className={`burger-menu`}>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
							</div>
						</div>
					</div>
				)}
				<div className="img-container">
					<div className="overlay"></div>
					<Image
						src="/contact/banner.png"
						alt="search img"
						layout="fill"
						objectFit="cover"
					/>
				</div>
				<div
					className={`enquiry-section booking menu ${
						formSubmitted ? "formSubmitted" : ""
					}`}
				>
					{!formSubmitted ? (
						<>
							<FadeIn customClass="header">
								<h2 className="_eleX">{t("enquire.enquire_now")}</h2>
							</FadeIn>
							<div className={`form-container`}>
								<Formik
									initialValues={initialValues}
									validationSchema={validationSchema}
									onSubmit={(values, { resetForm, setFieldValue }) => {
										setIsSending(true)
										const data = { data: { ...values } }
										fetch(`${API_URL}/booking-forms`, {
											method: "POST",
											body: JSON.stringify(data),
											headers: {
												"Content-type": "application/json; charset=UTF-8",
											},
										})
											.then((response) => {
												if (response.status == 200) {
													resetForm()
													setIsSending(false)
													setFormSubmitted(true)
													window.gtag("event", "conversion", {
														send_to: "AW-11303956860/o0BMCIjcu9MYEPziko4q",
														first_name: values.first_name,
														last_name: values.last_name,
														project_name: values.project_name,
														email: values.email,
														phone: values.phone,
														message: values.message,
														type: `booking`,
													})
													let deviceType = /iPad/.test(navigator.userAgent)
														? "t"
														: /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
																navigator.userAgent
														  )
														? "m"
														: "d"
													window.criteo_q.push(
														{ event: "setAccount", account: 107694 },
														{
															event: "setEmail",
															email: values.email,
														},
														{
															event: "setEmail",
															email: values.email,
															hash_method: "sha256",
														},
														{
															event: "setEmail",
															email: values.email,
															hash_method: "md5",
														},
														{ event: "setSiteType", type: deviceType },
														{
															event: "trackTransaction",
															id: Math.floor(Math.random() * 99999999999) + 1,
															item: [{ id: "1", price: "1", quantity: "1" }],
														}
													)
												}
											})
											.catch((error) => {
												console.log(error, "error")
											})
									}}
								>
									{(formProps) => (
										<Form>
											<FadeIn customClass="form-wrapper">
												<div className="field-wrapper _eleX">
													<label
														className="file-input__label"
														htmlFor="fullName"
													>
														{t("enquire.firstName")}
													</label>
													<div className="input-wrapper">
														<Field
															placeholder={t("enquire.firstName")}
															name="first_name"
															className={"text-input"}
															disabled={formSubmitted || isSending}
														/>
													</div>

													<p className="errorMsg">
														<ErrorMessage name="first_name" />
													</p>
												</div>
												<div className="field-wrapper _eleX">
													<label
														className="file-input__label"
														htmlFor="fullName"
													>
														{t("enquire.lastName")}
													</label>

													<div className="input-wrapper">
														<Field
															placeholder={t("enquire.lastName")}
															name="last_name"
															className={"text-input"}
															disabled={formSubmitted || isSending}
														/>
													</div>

													<p className="errorMsg">
														<ErrorMessage name="last_name" />
													</p>
												</div>
												<div className="field-wrapper _eleX">
													<label className="file-input__label" htmlFor="email">
														{t("enquire.email")}
													</label>

													<div className="input-wrapper">
														<Field
															placeholder={t("enquire.email")}
															name="email"
															className={"text-input"}
															disabled={formSubmitted || isSending}
														/>
													</div>

													<p className="errorMsg">
														<ErrorMessage name="email" />
													</p>
												</div>
												<div className="field-wrapper _eleX">
													<label className="file-input__label" htmlFor="phone">
														{t("enquire.phone")}
													</label>

													<div className="input-wrapper">
														<Field
															placeholder={t("enquire.phone")}
															name="phone"
															className={"text-input"}
															disabled={formSubmitted || isSending}
															type="number"
														/>
													</div>

													<p className="errorMsg">
														<ErrorMessage name="phone" />
													</p>
												</div>
												<div className="field-wrapper _eleX select select1">
													<div className="input-wrapper">
														<label htmlFor="phaseOptions">Project</label>
														<Select
															isDisabled={formSubmitted || isSending}
															key={formSubmitted}
															name="project_name"
															instanceId="phaseOptions"
															options={projects}
															onChange={(e) => {
																formProps.setFieldValue("project_name", e.value)
															}}
															placeholder={t("enquire.project")}
															styles={customProjStyles}
															isSearchable={false}
														/>
													</div>
													<p className="errorMsg">
														<ErrorMessage name="project_name" />
													</p>
												</div>
												<div className="field-wrapper _eleX message-input">
													<label
														className="file-input__label"
														htmlFor="message"
													>
														{t("enquire.message")}
													</label>

													<div className="input-wrapper">
														<Field
															placeholder={t("enquire.message")}
															name="message"
															className={"text-input"}
															as="textarea"
															rows="3"
															disabled={formSubmitted || isSending}
														/>
													</div>

													<p className="errorMsg">
														<ErrorMessage name="message" />
													</p>
												</div>
												<button
													type="submit"
													style={{ backgroundColor: "#31353d" }}
													className={`_eleX ${
														!isMobile && "button--isi"
													} button-section black-button`}
													disabled={formSubmitted || isSending}
												>
													<span>
														<ArrowsSpliting>
															{isSending
																? "Sending..."
																: formSubmitted
																? t("enquire.thank_you")
																: t("enquire.send")}
														</ArrowsSpliting>
													</span>
												</button>
											</FadeIn>
										</Form>
									)}
								</Formik>
							</div>
						</>
					) : (
						<AnimatePresence>
							<motion.div
								initial={{ opacity: 0, x: 30 }}
								whileInView={{ opacity: 1, x: 0 }}
								transition={{ delay: 0.1, duration: 0.5 }}
								viewport={{ once: true }}
								className="thank-message"
							>
								<h2 className="_eleY">Thank You for reaching out!</h2>
								<h2 className="_eleY">We will contact you shortly.</h2>
							</motion.div>
						</AnimatePresence>
					)}
				</div>
			</motion.div>
		</>
	)
}

export default BookingMenu
