import { AnimatePresence, motion, useCycle } from "framer-motion"
import Image from "next/image"
import Router, { useRouter } from "next/router"
import React, { useContext, useEffect, useState } from "react"
import { isMobileContext } from "../../contexts/isMobileContext"
import Button from "./Button"
import MobileMenu from "./MobileMenu"
import MobileSearchMenu from "./MobileSearchMenu"
import { useTranslation } from "next-i18next"
import { nextRouteContext } from "../../contexts/nextRouteContext"
import { phaseColorContext } from "../../contexts/phaseColorContext"
import DropDownMenu from "./DropDownMenu"
import { subMenuAnimate } from "../../utils/Animations"
import { isMenuOpendContext } from "../../contexts/isMenuOpendContext"
import ArrowsSpliting from "../Animations/ArrowsSpliting"
import BookingMenu from "./BookingMenu"
const MainNav = ({ tabs }) => {
	const { phaseColor } = useContext(phaseColorContext)
	const { t } = useTranslation("common")
	const { locale, asPath } = useRouter()
	const { isMobile } = useContext(isMobileContext)
	const {
		setIsMenuOpenedContext,
		searchMenuOpened,
		isMenuOpenedContext,
		isDevelopMenuOpened,
		setIsDevelopMenuOpened,
		isBookingMenuOpened,
		setIsBookingMenuOpened,
		isMenuOpened,
	} = useContext(isMenuOpendContext)
	const { setNextRoute } = useContext(nextRouteContext)
	const [isHover, toggleHover] = useState(false)
	const toggleMobileMenuOpen = () => {
		document.body.classList.toggle("no-scroll")
		setIsMenuOpenedContext(!isMenuOpenedContext)
	}
	const toggleHoverMenu = (state, devMenuState) => {
		toggleHover(state)
		setIsDevelopMenuOpened(devMenuState)
	}
	useEffect(() => {
		if (isMenuOpenedContext || isBookingMenuOpened) {
			document.body.classList.add("no-scroll")
		} else {
			document.body.classList.remove("no-scroll")
		}
	}, [isMenuOpenedContext, isBookingMenuOpened])
	useEffect(() => {
		if (searchMenuOpened || isBookingMenuOpened) {
			setIsMenuOpenedContext(false)
		}
	}, [searchMenuOpened, isBookingMenuOpened])

	return (
		<>
			<div
				className={`mainnav-container ${isMenuOpenedContext && "open-nav"}
            ${
							asPath.includes("/media-center/") &&
							asPath != "/media-center" &&
							!isMenuOpenedContext
								? "black-bg"
								: ""
						}
            `}
			>
				<div
					onClick={() => {
						setNextRoute(t("navbar.home"))
						Router.push("/")
					}}
				>
					<div className="logo-container">
						<Image
							src={`${
								asPath.includes("/media-center/") &&
								asPath != "/media-center" &&
								!isMenuOpenedContext
									? "/icons/newLogo.png"
									: "/icons/newLogoBlack.png"
							}`}
							alt="logo"
							objectFit="contain"
							layout="fill"
							priority
						/>
					</div>
				</div>
				<div className="links-container bold">
					<div
						className="link-wrapper"
						onClick={() => {
							setNextRoute(t("navbar.who_we"))
							Router.push("/who-we-are")
						}}
					>
						<div
							className={`link ${
								asPath.includes("/who-we-are") ? "active" : ""
							}`}
						>
							<ArrowsSpliting>{`${t("navbar.who_we")}`}</ArrowsSpliting>
						</div>
					</div>
					<motion.div
						className="link-wrapper development"
						onHoverStart={() => toggleHoverMenu(true, true)}
						onHoverEnd={() => toggleHoverMenu(false, false)}
					>
						<div
							className={`link ${asPath.includes("/projects") ? "active" : ""}`}
						>
							<ArrowsSpliting>{`${t("navbar.developments")}`}</ArrowsSpliting>
							<div className="icon-container">
								<Image
									src={`${
										asPath.includes("/projects")
											? "/icons/arrow-down-black.svg"
											: "/icons/arrow-down.svg"
									}`}
									alt="logo"
									objectFit="contain"
									layout="fill"
								/>
							</div>
						</div>
					</motion.div>
					<div
						className="link-wrapper"
						onClick={() => {
							setNextRoute(t("navbar.services"))
							Router.push("/services")
						}}
					>
						<div
							className={`link ${asPath.includes("/services") ? "active" : ""}`}
						>
							<ArrowsSpliting>{`${t("navbar.services")}`}</ArrowsSpliting>
						</div>
					</div>
					<div
						className="link-wrapper"
						onClick={() => {
							setNextRoute(t("navbar.360_works"))
							Router.push("/360-works")
						}}
					>
						<div
							style={{ direction: "ltr" }}
							className={`link ${
								asPath.includes("/360-works") ? "active" : ""
							}`}
						>
							<ArrowsSpliting works>{`360 WORKS`}</ArrowsSpliting>
						</div>
					</div>
					<div
						onClick={() => {
							setNextRoute(t("navbar.media_center"))
							Router.push("/media-center")
						}}
					>
						<div
							className={`link ${
								asPath.includes("/media-center") ? "active" : ""
							}`}
						>
							<ArrowsSpliting>{`${t("navbar.media_center")}`}</ArrowsSpliting>
						</div>
					</div>
					<div
						className="link-wrapper"
						onClick={() => {
							setNextRoute(t("navbar.contactUs"))
							Router.push("/contact-us")
						}}
					>
						<div
							className={`link ${
								asPath.includes("/contact-us") ? "active" : ""
							}`}
						>
							<ArrowsSpliting>{`${t("navbar.contactUs")}`}</ArrowsSpliting>
						</div>
					</div>
					{/* <div  className='link-wrapper' onClick={() => { setNextRoute(t("navbar.career")); Router.push(locale == "ar" ? "https://careers.hpd.com.eg/ar/"  : "https://careers.hpd.com.eg" , '_blank') }}>
                        <div className={`link`}>
                            <ArrowsSpliting>{`${t("navbar.career")}`}</ArrowsSpliting>
                        </div>
                    </div> */}
					<div
						className="link-wrapper"
						onClick={() => {
							setNextRoute(t("navbar.career"))
							window.open(
								locale === "ar"
									? "https://careers.hpd.com.eg/ar/"
									: "https://careers.hpd.com.eg",
								"_blank"
							)
						}}
					>
						<div className={`link`}>
							<ArrowsSpliting>{`${t("navbar.career")}`}</ArrowsSpliting>
						</div>
					</div>
					<a
						className="link-wrapper"
						// onClick={() => {
						// 	setNextRoute(t("navbar.makai"))
						// 	window.open(  
						// 		"www.makaicabanas.com",
						// 		"_blank"
						// 	)
						// }}
						href="https://www.makaicabanas.com"
						target="_blank"
						rel="noreferrer"
					>
						<div className={`link`}>
							<ArrowsSpliting>{`${t("navbar.makai")}`}</ArrowsSpliting>
						</div>
					</a>
				</div>
				<div
					className="book-button"
					onClick={() => setIsBookingMenuOpened(true)}
				>
					<Button
						splitType="char"
						text={`${t("common.book_now")}`}
						blackStyle
						phaseColor={phaseColor}
					/>
				</div>
				{isMobile && (
					<div
						className={`burger-menu-container ${
							isMenuOpenedContext && "open-menu"
						}`}
						onClick={() => {
							toggleMobileMenuOpen()
						}}
					>
						<div
							className={`burger-menu ${isMenuOpenedContext && "open"}
                `}
						>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
				)}
			</div>
			<AnimatePresence>
				{!isMobile && (
					<motion.div
						className="dev-menu"
						initial="exit"
						animate={isHover && isDevelopMenuOpened ? "enter" : "exit"}
						variants={subMenuAnimate}
						onMouseEnter={() => toggleHoverMenu(true, true)}
						onMouseLeave={() => toggleHoverMenu(false, false)}
					>
						<DropDownMenu tabs={tabs} toggleHover={toggleHover} />
					</motion.div>
				)}
			</AnimatePresence>
			<AnimatePresence>
				{isMenuOpenedContext && <MobileMenu tabs={tabs} />}
			</AnimatePresence>
			<AnimatePresence>
				{isBookingMenuOpened && <BookingMenu tabs={tabs} />}
			</AnimatePresence>
		</>
	)
}

export default MainNav
